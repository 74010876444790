html, body {
  font-family: 'Raleway', sans-serif !important;
  width: 100%;
  overflow-x: hidden;
}

p {
  font-size: 18px !important;
  line-height: 30px !important;
}

h1 {
  font-size: 9vw;
  letter-spacing: -1px;
  font-family: 'Raleway', sans-serif !important;
}

@media (min-width: 600px) {
  h1 {
    font-size: 66px;
    /* Set a fixed font size for larger viewports */
  }
}

h2 {
  font-size: 7vw;
  letter-spacing: -1px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif !important;
}

@media (min-width: 600px) {
  h2 {
    font-size: 50px;
    /* Set a fixed font size for larger viewports */
  }
}

h3 {
  font-size: 6vw;
  font-weight: 700;
  font-family: 'Raleway', sans-serif !important;
  color: #262C5B;
}

h3 span {
  font-weight: 800;
  color: #3366FF;
}

@media (min-width: 600px) {
  h3 {
    font-size: 40px;
    /* Set a fixed font size for larger viewports */
  }
}

h4 {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Raleway', sans-serif !important;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ant-btn {
  font-size: 18px;
  border-radius: 10px;
  height: 60px;
  margin: 5px;
  font-weight: 500;
  padding: 0 30px;
}

.ant-btn.ant-btn-primary {
  background: #F76260 !important;
}

.ant-btn.ant-btn-primary:hover {
  background: #f87a78 !important;
}

.ant-btn.ant-btn-secondary {
  border-color: #fff;
  color: #fff;
  background: transparent !important;
}

.ant-btn.ant-btn-secondary:hover {
  color: #000;
  background: white !important;
}

.ant-btn.ant-btn-white {
  border-color: #fff;
  color: #000;
  background: #fff !important;
  padding: 0 20px;
}

.ant-btn.ant-btn-white:hover {
  color: #fff;
  border-color: #F76260;
  background: #F76260 !important;
}

.ant-btn.ant-btn-transparent {
  color: #fff;
  background: transparent !important;
  height: 40px;
  padding: 0 20px;
}

.ant-btn.ant-btn-transparent:hover {
  color: #F76260;
}

.ant-btn.ant-btn-blackoutline {
  border-color: #000;
  color: #000;
  background: transparent !important;
  height: 60px;
  padding: 0 30px;
}

.ant-btn.ant-btn-blackoutline:hover {
  color: #fff;
  background: #000 !important;
}

.ant-btn.ant-btn-package {
  border-color: #fff;
  color: #fff;
  background: #F76260 !important;
  height: 40px;
  width: 100%;
  font-size: 12px;
  margin-top: 20px;
  font-weight: 600;
}

.ant-btn.ant-btn-package:hover {
  color: #fff;
  border-color: #F76260;
  background: #F76260 !important;
}

#header {
  background-color: #253357;
  width: 100%;
  overflow: hidden;
  padding: 30px 15px;
}

#header .logo {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 4px;
}

#header .menu {
  color: #fff;
}

#header .menu ul {
  display: inline-block;
  padding: 0;
  margin: 0;
}

#header .menu ul li {
  list-style: none;
  display: inline;
  margin: 0 40px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 3px;
}

#header .menu ul li:hover {
  color: #F76260;
}

#footer {
  background-color: #253357;
  width: 100%;
  padding: 50px 15px;
  color: #fff;
}

#footer .logo {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 44px;
  font-weight: 100;
  letter-spacing: 4px;
  margin-top: 40px;
}

#footer h2 {
  text-align: center;
}

#footer h3 {
  text-align: center;
  color: #fff;
}

#footer p {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

#footer h5 {
  font-weight: 500;
  font-size: 14px;
  margin-top: 0;
  color: #000;
}

#footer ul {
  padding: 0;
}

#footer ul li {
  list-style: none;
  margin: 10px 0;
}

.fir {
  animation: fadeInRight 1s ease forwards;
}

.fil {
  animation: fadeInLeft 1s ease forwards;
}

#banner {
  background-color: #253357;
  padding: 0px 15px 0px 15px;
}

#banner img {
  width: 100%;
}

#banner .heroPreText {
  color: #F76260;
  font-size: 30px;
}

#banner .heroText {
  animation: fadeInDown 1s ease forwards;
  max-width: 900px;
  margin-top: 20px;
}

#banner .heroText h1 {
  font-weight: 700;
  color: #fff;
  margin: 0;
}

#banner .heroText h1 span {
  font-weight: 800;
  color: #3366FF;
}

#banner .heroText h4 {
  color: #7A828A;
  font-size: 22px;
  line-height: 34px;
  font-weight: 400;
  margin: 20px auto;
}

#banner .buttons {
  animation: fadeIn 1s ease forwards;
  margin-top: 25px;
}

#banner .checks {
  animation: fadeInUp 1s ease forwards;
  margin-top: 25px;
  font-size: 16px;
  color: #2d3547;
  margin-bottom: 80px;
}

#banner .checks .check {
  display: inline;
  margin: 5px;
}

#banner .checks .check span {
  font-weight: bold;
  color: #F76260;
}

#banner .bannerImage {
  overflow: hidden;
  margin-bottom: 15px;
}

#banner .bannerImage img {
  width: 100%;
  border-radius: 15px;
}

#builder-banner {
  background-color: #000;
  padding: 50px 15px 50px 15px;
}

#builder-banner .heroText {
  animation: fadeInDown 1s ease forwards;
}

#builder-banner .heroText h1 {
  color: #fff;
  margin-bottom: 0;
}

#builder-banner .heroText h4 {
  color: #E5E5E5;
  max-width: 500px;
  line-height: 28px;
}

#builder-banner .buttons {
  animation: fadeIn 1s ease forwards;
  margin-top: 25px;
}

#builder-banner .checks {
  animation: fadeInUp 1s ease forwards;
  margin-top: 25px;
  font-size: 14px;
  color: #fff;
}

#builder-banner .checks .check {
  display: inline;
  margin: 5px;
}

#builder-banner .bannerImage {
  overflow: hidden;
  margin-bottom: 15px;
}

#builder-banner .bannerImage img {
  width: 100%;
  border-radius: 15px;
}

.homeBanner {
  padding: 70px;
}

.homeBanner .homeIcon img {
  width: 60px;
}

.container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 30px;
}

.container.thin {
  max-width: 900px;
}

.section {
  position: relative;
  padding: 50px 30px;
}

.section .bannerImage {
  animation: fadeInUp 0.5s ease forwards;
  max-width: 1140px;
  text-align: center;
  position: absolute;
  margin-top: -350px;
}

.section .bannerImage img {
  width: 95%;
}

.section.blackrounded {
  background-color: #000;
  border-radius: 30px;
  max-width: 1500px;
  margin: 0 auto 50px auto;
  color: #fff;
}

.section.blackrounded h2 {
  color: #fff;
  text-align: center;
}

.section.grey {
  background-color: #F3F4FB;
}

.section.primary {
  background-color: #253357;
  color: #fff;
}

.section p {
  color: #486580;
  font-size: 16px;
  line-height: 26px;
}

.section p.review {
  color: #D9E2EC;
  font-size: 36px;
  line-height: 50px;
  font-weight: 300;
  text-align: center;
}

.home-team img {
  width: 100%;
}

.review {
  position: relative;
}

.review .review-image {
  border-radius: 50%;
  width: 275px;
  height: 275px;
  overflow: hidden;
  margin: 0 auto;
}

.review .review-image img {
  width: 100%;
}

.review .review-name {
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
}

.review .review-company {
  color: #000;
  font-size: 14px;
  margin-top: 3px;
  font-weight: 300;
}

.review .reviewArrow {
  cursor: pointer;
  display: inline;
  margin: 0 5px;
}

.review .reviewText {
  color: #000;
  font-size: 30px;
  line-height: 40px;
  z-index: 2;
  position: relative;
}

.review .quoteMark {
  color: #E4E5EB;
  font-size: 600px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  position: absolute;
  z-index: 1;
  top: -100px;
  right: -50px;
}

.homeImage img {
  width: 95%;
}

.service {
  margin-top: 30px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
  border-radius: 25px;
}

.service .image {
  font-size: 90px;
  text-align: left;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden;
}

.service .image img {
  width: 100%;
}

.service h3 {
  color: #fff;
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 10px;
}

.service p {
  color: #fff;
}

.option {
  margin-bottom: 20px;
}

.option h3 {
  margin-bottom: 0;
}

.option p {
  margin-top: 10px;
  font-size: 14px;
}

.package {
  border: 1px solid #f1f1f1;
  margin: 20px 0;
  position: relative;
  cursor: pointer;
}

.package .checked {
  position: absolute;
  right: 10px;
  top: 10px;
}

.package.selected {
  border: 1px solid #5E4EE0;
}

.package .name {
  padding: 20px;
}

.package .name h5 {
  margin: 0;
  font-size: 20px;
}

.package .name p {
  line-height: 18px;
  font-size: 12px;
}

.package .name ul {
  margin: 0;
  padding: 10px 0 0 0;
}

.package .name ul li {
  list-style: none;
  font-size: 12px;
  color: #627A91;
  margin: 8px 0;
}

.package .priceArea {
  background-color: #f1f1f1;
  text-align: right;
  padding: 20px;
}

.package .priceArea .price {
  margin: 5px 0;
  font-size: 12px;
}

.package .priceArea .price .total {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -2px;
}

.package.selected {
  border: 1px solid #5E4EE0;
}

.package.selected .priceArea {
  background-color: #5E4EE0;
  color: white;
}

.results {
  margin: 50px 100px;
  border-radius: 50px;
  min-height: 400px;
  background-color: 'white';
  overflow: hidden;
  background-color: white;
}

.results .results-container {
  height: 400px;
}

.results .network {
  display: flex;
  align-items: center;
}

.results .network.fb {
  background-color: #1B74EC;
}

.results .network.insta {
  background-color: #BF3B8E;
}

.results .network.linkedin {
  background-color: #006FAD;
}

.results .network.google {
  background-color: #DF5634;
}

.results .network.tiktok {
  background-color: #000;
}

.results .network.website {
  background-color: #1E7197;
}

.results .network.seo {
  background-color: #ff00ff;
}

.results .network .icon {
  display: inline;
}

.results .network .description {
  flex: 1;
  display: inline;
  font-size: 30px;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-1b0bdye).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #F76260;
  border-color: #F76260;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-1b0bdye).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #F76260;
  border-color: #F76260;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-checkbox-checked .ant-checkbox-inner {
  background: #F76260;
  border-color: #F76260;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #F76260;
  border-color: #F76260;
}

.serviceBoxes {
  margin-top: 50px;
}

.serviceBoxes .serviceItem {
  border: 1px solid #F76260;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
}

.serviceBoxes .serviceItem .serviceIcon {
  font-size: 26px;
  color: #F76260;
}

.serviceBoxes .serviceItem .serviceName {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
}

.feature {
  margin: 50px 0 100px 0;
}

.feature .featureHeader {
  color: #F76260;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.feature h2 {
  margin-top: 10px;
}

.feature .featureImage img {
  width: 90%;
}

.resultSection {
  margin: 30px 0;
}

.resultSection .result {
  background-color: #fff;
  margin: 15px 0;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.resultSection .result .content {
  padding: 40px;
}

.resultSection .result .content .name {
  font-size: 16px;
  font-weight: 600;
}

.resultSection .result .content .number {
  color: #F76260;
  font-size: 50px;
  font-weight: 800;
  margin-top: 25px;
}

.resultSection .result .content .number .icon {
  display: inline;
  font-size: 30px;
}

.resultSection .result .content .number .no {
  display: inline;
}

.resultSection .result .content .description {
  margin-top: 20px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

.resultSection .result .image {
  height: 200px;
  background-size: cover;
  background-position: center;
}

.resultSection .result .image img {
  width: 100%;
}

.savePackage {
  max-width: 500px;
  margin: 0 auto;
}

.savePackage .ant-input {
  height: 50px;
}

.doyouknowsection {
  max-width: 1000px;
  margin: 30px auto;
}

.doyouknowsection .doyouknowitem {
  font-size: 20px;
  color: #2d3547;
  line-height: 30px;
  margin: 20px 0;
  border-left: 5px solid #F76260;
  padding: 15px 15px 15px 25px;
  font-weight: 300;
}

.whatsisaboutitem {
  max-width: 900px;
  margin: 50px auto 0;
}

.whatsisaboutitem img {
  width: 90%;
}

.whatsisaboutitem .title {
  color: #262C5B;
  font-size: 24px;
  font-weight: 800;
}

.whatsisaboutitem p {
  font-size: 18px;
  line-height: 30px;
}

.shouldichange {
  margin: 50px auto 0;
}

.shouldichange .shouldichangeitem {
  margin: 30px 0;
  background: #fff;
  border: 1px solid #e5e8f0;
  -webkit-box-shadow: 0 14px 44px 0 rgba(208, 213, 224, 0.5);
  box-shadow: 0 14px 44px 0 rgba(208, 213, 224, 0.5);
  border-radius: 8px;
  padding: 40px;
}

.shouldichange .shouldichangeitem .title {
  color: #262C5B;
  font-size: 20px;
  font-weight: 800;
}

.shouldichange .shouldichangeitem p {
  font-size: 18px;
  line-height: 30px;
}

.questions {
  margin: 100px 0;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
}

.questions h3 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 70px;
}

.books {
  margin-top: 50px;
  text-align: center;
}
